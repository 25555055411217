import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import { safeName } from "../common/js/utils"

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/resetpwd",
    name: "restPwd",
    component: () => import("../views/ResetPwd.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/Payment.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/PaymentDetail.vue"),
  },
  {
    path: "/child",
    name: "child",
    component: () => import("../views/Child.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import("../views/PrivacyPolicy.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/") {
    if (getQuery("child_id")) {
      localStorage.setItem("childId", getQuery("child_id"));
      localStorage.setItem("childName", safeName(getQuery("child_name")));
      localStorage.setItem("schoolName", getQuery("school_name"));
      localStorage.setItem("className", getQuery("class_name"));
    }
    if (localStorage.getItem("phone")) {
      next("/payment");
    } else {
      // if (!getQuery("code")) {
      //   var url = "";
      //   window.location.href = url;
      // } else {
        next();
      // }
    }
  } else {
    next();
  }
});

/**
 * 获取url的query中的参数
 */
function getQuery(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export default router;
